<template>
    <div>
        <NavBreadcrumb v-if="!asComponent"></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" label-width="90px" label-position="right" :model="searchDto" size="mini">
                <el-form-item v-if="!asComponent" label="逆变器SN号" prop="deviceSn" class="search-sn">
                    <el-input class="input" v-model="searchDto.deviceSn" :disabled="asComponent" clearable placeholder="逆变器SN号"/>
                </el-form-item>
                <el-form-item v-if="!asComponent" label="产品型号" prop="productModelId" class="search-product-model">
                    <el-select class="input" placeholder="全部" clearable v-model="searchDto.productModelId"
                           @change="handleProductModelSelectChangeEvent">
                        <el-option v-for="item in productModelList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="操作名称" prop="operationCode" class="search-comm-name">
                    <el-select class="input" v-model="searchDto.operationCode" clearable filterable placeholder="请选择产品型号">
                        <el-option v-for="item in operationNameList" :key="item.operationCode" :label="item.operationName" :value="item.operationCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="操作时间" prop="operationTime" class="search-date">
                    <el-date-picker v-model="updateTimeRange" type="daterange" value-format="yyyy-MM-dd" class="input"
                        :picker-options="pickerOptions" start-placeholder="开始日期" range-separator="-" end-placeholder="结束日期" >
                    </el-date-picker>
                </el-form-item>
                <el-form-item class="search-btn">
                    <el-button type="primary" @click="handleQueryOperationLogListClickEvent">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table border stripe size="small" :data="tableData" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                      style="width: 100%" v-loading="listLoading" max-height="550px">
                <el-table-column prop="deviceSn" label="逆变器SN号" width="210px"/>
                <el-table-column prop="productModelName" label="产品型号" width="150px"/>
                <el-table-column prop="operationName" label="操作名称" min-width="100px" :formatter="operationNameFormatter"/>
                <el-table-column prop="operationRespCode" label="操作结果" width="80px" :formatter="operationCodeFormatter"/>
                <el-table-column prop="operationUserName" label="操作人" width="160px"/>
                <el-table-column prop="operationTime" label="操作时间" width="150px" align="center"/>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal"
                        :current-page.sync="searchDto.pageNum"
                        :page-sizes="[10, 50, 100]"
                        @current-change="handleCurrentChangeEvent"
                        @size-change="handleSizeChangeEvent"/>
            </div>
        </div>
        <EsaioOperationLogParamDetail ref="EsaioOperationLogParamDetailPage" @queryOperationLogList="queryOperationLogList"/>
    </div>
</template>

<script>
    import message from '@/utils/message';
    import global from '@/components/Global.vue';
    import NavBreadcrumb from '@/components/NavBreadcrumb.vue';
    import {queryOperationLogList, queryOperationLogNameList, queryProductModelList} from '@/api/esaio/oplog/operationLogApi';
    import EsaioOperationLogParamDetail from '@/views/esaio/oplog/operation-log-param-detail.vue'
    export default {

        name: 'EsaioOperationLogList',
        components: {NavBreadcrumb, EsaioOperationLogParamDetail},
        data() {
            return {

                asComponent: false,
                searchDto: {

                    pageNum: 1,
                    pageSize: 10,
                    deviceSn: '',
                    productModelId: null,
                    operationCode: null,
                    startTime: null,
                    endTime: null
                },
                updateTimeRange: [],
                listLoading: false,
                operationNameList: [],
                tableData: [],
                tableTotal: 0,
                productModelList: [],
                pickerOptions: {

                    disabledDate(time) {

                        return time.getTime() > Date.now();
                    }
                }
            };
        },
        methods: {

            initEsaioOperationLogPage() {

                let p1 = this.queryProductModelList()
                let p2 = this.queryOperationLogList();
                this.listLoading = true;
                let that = this;
                Promise.all([p1, p2]).then(() => {

                    that.listLoading = false;
                }).catch(function(err) {

                    message.error(err);
                    that.listLoading = false;
                });
            },
            asComponentInitPage(deviceSn, productModelId) {

                this.asComponent = true;
                this.searchDto.productModelId = productModelId;
                this.searchDto.deviceSn = deviceSn;
                this.searchDto.operationCode = null;
                this.searchDto.pageNum = 1;
                this.searchDto.pageSize = 10;
                this.updateTimeRange = [];
                this.startTime = null;
                this.endTime = null;
                this.initEsaioOperationLogPage();
                this.queryOperationLogNameList(productModelId);
            },
            handleQueryOperationLogListClickEvent() {

                this.listLoading = true;
                let that = this;
                this.queryOperationLogList().then(() => {

                    that.listLoading = false;
                }).catch(function(err) {

                    that.listLoading = false;
                    message.error(err);
                });
            },
            queryProductModelList() {

                return new Promise((resolve, reject) => {

                    queryProductModelList().then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            this.productModelList = res.data;
                            resolve();
                        } else if (res.code === global.response_status_exception_msg) {

                            reject(res.msg);
                        } else {

                            reject('请求失败');
                        }
                    }).catch(() => {

                        reject('请求失败');
                    });
                });
            },
            queryOperationLogList() {

                this.searchDto.startTime = this.updateTimeRange ? this.updateTimeRange[0] : null;
                this.searchDto.endTime = this.updateTimeRange ? this.updateTimeRange[1] : null;
                if (this.searchDto.deviceSn) {

                    this.searchDto.deviceSn = this.searchDto.deviceSn.replace(/\\/g, '\\\\');
                }
                return new Promise((resolve, reject) => {

                    queryOperationLogList(this.searchDto).then((res) => {

                        if (res.code === global.response_status_success_obj) {

                            this.tableTotal = res.data.total;
                            this.tableData = res.data.result;
                            resolve();
                        } else if (res.code === global.response_status_exception_msg) {

                            reject(res.msg);
                        } else {

                            reject('请求失败');
                        }
                    }).catch(() => {

                        reject('请求失败');
                    });
                })
            },
            handleCurrentChangeEvent(val) {

                this.searchDto.pageNum = val;
                this.handleQueryOperationLogListClickEvent();
            },
            handleSizeChangeEvent(val) {

                this.searchDto.pageSize = val;
                this.handleQueryOperationLogListClickEvent();
            },
            handleProductModelSelectChangeEvent(productModelId) {

                this.searchDto.operationCode = null;
                if (productModelId) {

                    // 调用后台接口查询操作名称
                    this.queryOperationLogNameList(productModelId);
                } else {

                    this.operationNameList = [];
                }
            },
            queryOperationLogNameList(productModelId) {

                let params = {

                    productModelId: productModelId
                };
                queryOperationLogNameList(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.operationNameList = res.data;
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            openCommandParamDetailPage(row) {

                this.$refs.EsaioOperationLogParamDetailPage.initCommandParamDetailPage(row);
            },
            operationNameFormatter(row) {

                return <div>{<el-button onClick={this.openCommandParamDetailPage.bind(null, row)} style="height:20px;padding:0" size="mini" type="text">{row.operationName}</el-button>}</div>;
            },
            operationCodeFormatter(row) {

                if (row.operationRespCode === '200') {

                    return <span style="color: #00D30F">成功</span>;
                } else if (row.operationRespCode === '500') {

                    return <span style="color: #d5040d">失败</span>;
                } else if (row.operationRespCode === '600') {

                    return <span style="color: #d5040d">未响应</span>;
                } else if (row.operationRespCode === '700') {

                    return <span style="color: #00D30F">已下发</span>;
                } else {

                    return '--';
                }
            }
        },
        mounted() {

            this.initEsaioOperationLogPage();
        }
    }
</script>

<style lang="scss" scoped>

    .search-sn .input {

        width: 210px;
    }
    .search-product-model .input {

        width: 190px;
    }
    .search-comm-name .input {

        width: 218px;
    }
    .search-date .input {

        width: 210px;
        height: 29px;
    }
    .search-btn {

        margin-left: 10px;
    }
    .pagination {

        margin-top: 15px;
        text-align: right;
    }
    ::v-deep .el-dialog {

        max-height: 78vh;
        overflow: auto;
    }
</style>
