import axios from 'axios';
import global from '@/components/Global.vue';

let esaioServerBase = global.esaioServerBase;

// 分页查询储能操作日志.
export const queryOperationLogList = params => {

    return axios.post(esaioServerBase + '/operation/log/queryOperationLogList', params);
};

/**
 * 分页查询已删除的储能设备操作日志.
 * @param params 已删除的设备编号
 * @returns 查询结果
 */
export const queryRemovedDeviceOpLogList = params => {

    return axios.post(esaioServerBase + '/operation/log/queryRemovedDeviceOpLogList', params);
};

// 查询操作日志参数详情
export const queryOperationLogParamDetail = params => {

    return axios.post(esaioServerBase + '/operation/log/queryOperationLogParamDetail', params);
};

// 查询产品型号
export const queryProductModelList = params => {

    return axios.post(esaioServerBase + '/operation/log/queryProductModelList', params);
};

// 查询操作名称列表
export const queryOperationLogNameList = params => {

    return axios.post(esaioServerBase + '/operation/log/queryOperationLogNameList', params);
};

