<template>
    <el-dialog title="参数详情" :visible.sync="paramDetailDialogVisible" :destroy-on-close="true" center
           @close="closeEsaioCommandParamDetailPage" id="esaioStationInfoDetailDialog"
           :append-to-body="true" :width="dialogWidth" top="0" :close-on-click-modal="false">
        <el-form label-width="250px" :inline="true" disabled size="mini">
            <el-form-item v-for="item in paramDetailList" :key="item.fieldName" :label="item.fieldName">
                <el-switch v-if="item.fieldType === '2'" class="item" v-model="item.fieldValue" active-value="1" inactive-value="0"/>
                <el-select v-else-if="item.fieldType === '1'" class="item" v-model="item.fieldValue">
                    <el-option v-for="vFor in item.selectList" :key="vFor.value" :label="vFor.label" :value="vFor.value">
                    </el-option>
                </el-select>
                <el-input v-else-if="item.fieldType === '0' && item.unit && item.unit !== ''" class="item" :value="item.fieldValue">
                    <template slot="append">{{item.unit}}</template>
                </el-input>
                <el-input v-else class="item" :value="item.fieldValue"/>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import {queryOperationLogParamDetail} from '@/api/esaio/oplog/operationLogApi';
    import global from '@/components/Global';
    import message from "@/utils/message";

    export default {

        name: 'EsaioOperationLogParamDetail',
        data() {

            return {

                paramDetailDialogVisible: false,
                dialogWidth: '600px',
                paramDetailList: []
            }
        },
        methods: {

            initCommandParamDetailPage(row) {

                this.paramDetailDialogVisible = true;
                let params = {

                    id: row.id,
                    deviceSn: row.deviceSn
                }
                this.paramDetailList = [
                    {
                        'fieldName': '逆变器SN号',
                        'fieldValue': row.deviceSn,
                        'fieldType': '0'
                    },
                    {
                        'fieldName': '产品型号',
                        'fieldValue': row.productModelName,
                        'fieldType': '0'
                    },
                    {
                        'fieldName': '操作名称',
                        'fieldValue': row.operationName,
                        'fieldType': '0'
                    },
                    {
                        'fieldName': '操作结果',
                        'fieldValue': this.operationCodeFormatter(row.operationRespCode),
                        'fieldType': '0'
                    },
                    {
                        'fieldName': '操作人',
                        'fieldValue': row.operationUserName,
                        'fieldType': '0'
                    },
                    {
                        'fieldName': '操作时间',
                        'fieldValue': row.operationTime,
                        'fieldType': '0'
                    },
                ];
                queryOperationLogParamDetail(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.paramDetailList = this.paramDetailList.concat(res.data);
                        let itemNum = this.paramDetailList.length;
                        if (itemNum > 10) {

                            this.dialogWidth = '1500px';
                        } else {

                            this.dialogWidth = '750px';
                        }
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            },
            closeEsaioCommandParamDetailPage() {

                this.paramDetailDialogVisible = false;
            },
            operationCodeFormatter(operationCode) {

                if (operationCode === '200') {

                    return '成功';
                } else if (operationCode === '500') {

                    return '失败';
                } else if (operationCode === '600') {

                    return '未响应';
                } else if (operationCode === '700') {

                    return '已下发';
                } else {

                    return '--';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    ::v-deep .el-input-group__append {

        width: 20px;
        text-align: center;
    }

    .item {

        width: 220px;
    }
</style>
